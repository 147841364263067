import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Typography, Space } from "antd";
import "./_detail.scss";

const { Text } = Typography;

const InfoHeader = () => {
  return (
    <div className="checkout-info-header">
      <div className="flight-info-content">
        <div className="route">
          <Text>Delhi</Text>
          <ArrowRightOutlined className="route-arrow" />
          <Text>Bengaluru</Text>
        </div>
        <Text type="secondary" className="details">
          Sat, 10 Sept | 1 Adult | Economy
        </Text>
      </div>
    </div>
  );
};

export default InfoHeader;
