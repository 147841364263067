import React from "react";
import { useNavigate } from "react-router-dom";
import "./_styles.scss";
import OrderConfirm from "../../components/confirmation/OrderConfirm";
import EmbedBox from "../../components/insurance/EmbedBox";
import { flightInsureBullets } from "../../const/nomad";
import DetailBox from "../../components/flight/DetailBox";

const PaySuccess: React.FC = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/insurance", { replace: true });
  };

  return (
    <div className="payment-success">
      <OrderConfirm confirmTitle="Booking Confirmed" bookingId="abc154" />
      <DetailBox />
      <EmbedBox
        heading="Your Trip is Insured"
        subtitle="Trip Secure by Bharatsure"
        poweredBy="Insurance Powered by ICICI Lombard"
        background="#F6FFF6"
        bulletPoints={flightInsureBullets}
        showButton={false}
      />
    </div>
  );
};

export default PaySuccess;
