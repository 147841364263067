import React from "react";
import { MenuOutlined } from "@ant-design/icons";
import "./_header.scss";
import { Sidebar } from "./Sidebar";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import InfoHeader from "../flight/InfoHeader";
import insPlus from "../../assets/payswift/Insurance+.png";

interface Props {
  logo?: React.ReactNode | string;
  children?: React.ReactNode;
  title?: string;
  section?: string;
}

const Header: React.FC<Props> = ({ logo, children, title, section }: Props) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const goBack = (link: string) => {
    if (title?.toLowerCase() !== "home") {
      navigate(`/${title?.toLowerCase().replace(/\s/g, "-")}${link}`);
    } else {
      navigate(link);
    }
  };

  const routeBasedHeader: any = {
    home: {
      "": {
        name: "Nomad",
        logo: true,
      },
      insurance: {
        name: "Insurance+",
        // customImg: insPlus,
        logo: true,
        // poweredBy: true
      },
      "book-flights": {
        title: "Book Flight",
        back: true,
        backLink: "/",
      },
      listing: {
        component: <InfoHeader />,
        back: true,
        backLink: "/book-flights",
      },
      checkout: {
        title: "Your Flight",
        back: true,
        backLink: "/listing",
      },
      payment: {
        blank: true,
        back: true,
        backLink: "/checkout",
      },
      "pay-success": {
        title: "Your Flight",
        back: true,
        backLink: "/",
      },
      trips: {
        title: "Trips",
        back: true,
        backLink: "/",
      },
    },
    payswift: {
      "": {
        name: "Payswift",
        logo: true,
      },
      insurance: {
        name: "Insurance+",
        // customImg: insPlus,
        logo: true,
      },
      "select-plan": {
        title: "Plans",
        back: true,
        backLink: "/insurance",
      },
      "fill-details": {
        title: "Details",
        back: true,
        backLink: "/select-plan",
      },
      cart: {
        title: "Cart",
        back: true,
        backLink: "/fill-details",
      },
      payment: {
        back: true,
        backLink: "/cart",
        blank: true,
      },
      "pay-success": {
        blank: true,
      },
      "my-policies": {
        title: "My Policies",
        back: true,
        backLink: "/insurance",
      },
    },
  };

  const currentRoute =
    routeBasedHeader?.[title?.toLowerCase() || ""]?.[
      section?.toLowerCase() || ""
    ];
  return (
    <div className="header">
      {!currentRoute?.back && (
        <MenuOutlined className="menu-icon" onClick={toggleSidebar} />
      )}
      <div
        className={`header-content ${
          currentRoute && currentRoute.back ? "with-back" : ""
        }`}
      >
        {((currentRoute && currentRoute.logo) || !currentRoute) &&
          logo &&
          (typeof logo === "string" ? (
            <img
              src={logo}
              alt="logo"
              onClick={() => navigate("/")}
              className="pointer"
            />
          ) : (
            logo
          ))}

        {/* {currentRoute && currentRoute.customImg && (
          <img src={currentRoute.customImg} alt={currentRoute.title} />
        )} */}

        {currentRoute && currentRoute.back && (
          <ArrowLeftOutlined
            className="back-button"
            onClick={() => goBack(currentRoute?.backLink)}
          />
        )}
        {currentRoute && currentRoute.component}

        {currentRoute && currentRoute.title && (
          <h1 className="title">{currentRoute.title}</h1>
        )}

        {children && <div className="child">{children}</div>}
        {/* {currentRoute?.poweredBy && <div style={
          {
            marginLeft: '24px'

          }
        }>Powered By Bharatsure</div>} */}
      </div>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
    </div>
  );
};

export default Header;
