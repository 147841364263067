import React from "react";
import FlightImg from "../../assets/nomad/flight.png";
import "./_detail.scss";

interface DetailBoxProps {
  logo?: string;
  deptCity?: string;
  arrivalCity?: string;
  deptTime?: string;
  arrTime?: string;
  duration?: string;
  stops?: string;
}

const DetailBox: React.FC<DetailBoxProps> = ({
  logo = FlightImg,
  deptCity = "DEL",
  arrivalCity = "BOM",
  deptTime = "Fri, Mar | 22:55",
  arrTime = "01:10",
  duration = "2h 15m",
  stops = "Non-Stop",
}) => {
  return (
    <div>
      <div className="flight-details" style={{paddingBottom: '24px'}}>
        <div className="flight-details-title">Flight Details</div>
        <div className="flight-info">
          <img src={logo} alt="Airline Logo" className="airline-logo" />
          <div className="flight-details-left">
            <div className="route">{`${deptCity}-${arrivalCity}`}</div>
            <div className="flight-time">
              {`${deptTime}-${arrTime} | ${duration} | ${stops}`}
            </div>
          </div>
        </div>
        {/* <div className="btn-area">
          <button className="details-button outline">More Details</button>
        </div> */}
      </div>
    </div>
  );
};

export default DetailBox;
