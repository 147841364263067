import React from "react";
import { Row, Col, Image, Typography, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import "./_listing.scss";

const { Title, Text } = Typography;
const { Option } = Select;

interface ImageProps {
  width?: number;
  height?: number;
}

interface ShortProductType {
  name: string;
  price: string;
  brand: string;
  img?: string;
  imgProps?: ImageProps;
  onClick?: () => void;
  showCartControls?: boolean;
  quantity?: number;
  onQuantityChange?: (value: number) => void;
  onDelete?: () => void;
}

const ProductListBox = ({
  name,
  price,
  brand,
  img,
  imgProps,
  onClick,
  showCartControls = false,
  quantity = 1,
  onQuantityChange,
  onDelete,
}: ShortProductType) => {
  return (
    <Row
      className="product-list-box"
      align="middle"
      justify="start"
      gutter={[16, 16]}
      wrap={false}
      onClick={onClick}
    >
      <Col className="product-list-img" xs={6}>
        <Image
          alt="product_image"
          src={img}
          width={imgProps?.width}
          height={imgProps?.height}
        />
      </Col>
      <Col className="product-list-details" xs={showCartControls ? 14 : 18}>
        <Title level={3} className={`product-list-text name ${showCartControls ? "cart" : ""}`}>
          {name}
        </Title>
        <Text className="product-list-brand">BRAND : {brand}</Text>
        <Text className="product-list-text">₹{price}</Text>
      </Col>
      {showCartControls && (
        <Col className="product-list-controls" xs={4}>
          <DeleteOutlined className="delete-icon" onClick={onDelete} />
          <Select
            value={quantity}
            style={{ width: 70 }}
            onChange={onQuantityChange}
          >
            <Option value={1}>01</Option>
            <Option value={2}>02</Option>
            <Option value={3}>03</Option>
          </Select>
        </Col>
      )}
    </Row>
  );
};

export default ProductListBox;