import { Drawer, Layout, Menu } from "antd";
import userIcon from "../../assets/payswift/userp-icon.png";
import { useNavigate } from "react-router-dom";
import { sidebarValues } from "../../const/nomad";
const { Sider } = Layout;

export const Sidebar = ({
  sidebarOpen,
  toggleSidebar,
}: {
  sidebarOpen: boolean;
  toggleSidebar: () => void;
}) => {
  const navigate = useNavigate();

  const handleOpen = (item: string) => {
    if (item === "Payment") {
      navigate("/pay", { replace: true });
    } else {
      navigate("/" + item?.toLowerCase().replace(/\s/g, "-"));
    }
  };

  return (
    <Drawer
      // trigger={null}
      // collapsible
      // collapsed={!sidebarOpen}
      // collapsedWidth={0}
      width={250}
      className={sidebarOpen ? "sidebar" : "sidebar closed"}
      style={{ height: "100vh" }}
      onClose={toggleSidebar}
      open={sidebarOpen}
      closable={false}
      placement="left"
    >
      <div className="profile">
        <div>
          <h2>Abhishek</h2>
          <a href="#">View Profile</a>
        </div>
        <img src={userIcon} alt="Profile Pic" />
      </div>
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={["3"]}
        onClick={() => toggleSidebar()}
      >
        {sidebarValues.map((item, index) => (
          <Menu.Item
            key={item.key}
            icon={<img src={item?.icon} alt={item.label} />}
            onClick={() => handleOpen(item?.link ?? item.label)}
          >
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
      <div className="version">Version 1.1</div>
    </Drawer>
  );
};
