import React from "react";
import { Col, Row } from "antd";
import TravelPack from "../cards/TravelPack";
import { useNavigate } from "react-router-dom";

const ExpandedOptions: React.FC = () => {
  const planBullets = [
    { key: "Cabin Bag", value: "8kgs" },
    { key: "Check In", value: "25kgs" },
    { key: "Cancellation", value: "Cancellation fee starting ₹3,500" },
    { key: "Date Change", value: "Date change fee starting ₹3,500" },
    { key: "Seat", value: "Free seat available" },
    { key: "Meal", value: "Get Complimentary Meals" },
  ];

  const otherPlanPoints = [...planBullets];

  const navigate = useNavigate();

  const handleBooking = () => {
    navigate("/checkout", { replace: true });
  };

  return (
    <Row className="expanded-options">
      <Col span={24}>
        <TravelPack
          price={9450}
          pack="Comfort Plus"
          onClick={() => handleBooking()}
          points={planBullets}
        />
      </Col>
      <Col span={24}>
        <TravelPack
          price={11887}
          pack="Flex"
          // onClick={() => handleBooking()}
          points={otherPlanPoints}
        />
      </Col>
    </Row>
  );
};

export default ExpandedOptions;
