import React, { useState } from "react";
import "./_styles.scss";
import CalendarIcon from "../../assets/nomad/Calender.png";
import AirplaneLanding from "../../assets/nomad/AirplaneLanding.png";
import AirplaneTakeoff from "../../assets/nomad/AirplaneTakeoff.png";
import { useNavigate } from "react-router-dom";
import { Radio } from "antd";

const BookFlightForm: React.FC = () => {
  const navigate = useNavigate();
  const [fromFocused, setFromFocused] = useState(false);
  const [toFocused, setToFocused] = useState(false);

  const toListing = () => {
    navigate("/listing", { replace: true });
  };

  return (
    <div className="book-flight-form">
      <form>
        <div className="trip-type">
          <Radio.Group defaultValue="onWay">
            <Radio value="onWay">On Way</Radio>
            <Radio value="roundTrip">Round Trip</Radio>
            <Radio value="multiCity">Multi City</Radio>
          </Radio.Group>
        </div>

        <div className={`form-group ${fromFocused ? "focused" : ""}`}>
          <label>From</label>
          <div
            className="input-group"
            onFocus={() => setFromFocused(true)}
            onBlur={() => setFromFocused(false)}
          >
            <img src={AirplaneTakeoff} alt="Airplane Takeoff" />
            <div>
              <strong>Delhi</strong> DEL
              <small>Indira Gandhi International Airport</small>
            </div>
          </div>
        </div>

        <div className={`form-group ${toFocused ? "focused" : ""}`}>
          <label>To</label>
          <div
            className="input-group"
            onFocus={() => setToFocused(true)}
            onBlur={() => setToFocused(false)}
          >
            <img src={AirplaneLanding} alt="Airplane Landing" />
            <div>
              <strong>Mumbai</strong> DXB
              <small>Subhash Chandra International Airport</small>
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group half-width">
            <label>Departure</label>
            <div className="input-group">
              <img src={CalendarIcon} alt="Calendar" />
              15/07/2022
            </div>
          </div>
          <div className="form-group half-width">
            <label>Return</label>
            <div className="input-group add-return">+ Add Return Date</div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group half-width">
            <label>Traveller</label>
            <div className="input-group">1 Adult</div>
          </div>
          <div className="form-group half-width">
            <label>Class</label>
            <div className="input-group">Economy</div>
          </div>
        </div>

        <button className="primary" onClick={toListing}>
          Proceed
        </button>
      </form>
    </div>
  );
};

export default BookFlightForm;
