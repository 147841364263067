import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TravelCard from "../../components/cards/TravelCard";
import FlightImg from "../../assets/nomad/flight.png";
import ExpandedOptions from "../../components/flight/ExapandedOptions";

const Listing = () => {
  const navigate = useNavigate();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const expandOptions = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="listing">
      <div className="product-list">
        {Array.from([0, 1, 2]).map((_, idx) => (
          <React.Fragment key={idx}>
            <TravelCard
              time={{
                departure: "21:15",
                arrival: "02:40",
                duration: "6h 55 mins",
              }}
              price={"9450"}
              name="Flight No.: 5G 8169"
              via="(1-Stop) BOM"
              img={FlightImg}
              imgProps={{
                width: 20,
                height: 20,
              }}
              startCityCode="DEL"
              endCityCode="DXB"
              onClick={() => idx === 0 && expandOptions(idx)}
            />
            {expandedIndex === idx && <ExpandedOptions />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Listing;
