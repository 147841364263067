import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import { useLocation } from "react-router-dom";
import NomadNexa from "../assets/nomad/Nomad-Nexa.svg";

const Layout: React.FC = (props: any) => {
  const location = useLocation();

  const getAppDetails = () => {
    if (location.pathname.includes("/")) {
      return {
        title: "Payswift",
        link: "/payswift",
        section: location.pathname.split("/")[2],
      };
    }
    return {
      title: "Home",
      link: "/",
      section: location.pathname.split("/")[1],
    };
  };

  return (
    <div
      className={`layout ${
        [
          "/listing",
          "/checkout",
          "/pay-success",
          "/trips",
          "/book-flights",
        ].some((path) => location?.pathname.includes(path))
          ? "bg-gray-all"
          : ""
      }`}
    >
      <Header
        title={getAppDetails()?.title}
        section={getAppDetails()?.section}
        logo={NomadNexa}
      />
      {props.children}
      <Footer />
    </div>
  );
};

export default Layout;
