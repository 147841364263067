import mdiCreditCardOutline from "../../assets/payswift/mdi_credit-card-outline.svg";
import bxSupport from "../../assets/payswift/bx_support.svg";
import mdiCogOutline from "../../assets/payswift/mdi_cog-outline.svg";
import mdiBellOutline from "../../assets/payswift/mdi_bell-outline.svg";
import healthIcon from "../../assets/payswift/healthicons_health-outline.svg";
import Rectangle4 from "../../assets/nomad/plane.svg";
import Rectangle41 from "../../assets/nomad/train.svg";
import Rectangle42 from "../../assets/nomad/taxi.svg";
import Rectangle43 from "../../assets/nomad/red-fort.svg";
import dest1 from "../../assets/nomad/dest-1.png";
import dest2 from "../../assets/nomad/dest-2.png";
import dest3 from "../../assets/nomad/dest-3.jpg";
import ordersIcon from "../../assets/buymore/order-box.svg";

export const sidebarValues = [
  { key: "0", label: "Trips", icon: ordersIcon, clickable: true },
  { key: "1", label: "Payment", icon: mdiCreditCardOutline, clickable: false },
  { key: "2", label: "Refer & Earn", icon: mdiBellOutline, clickable: false },
  { key: "3", label: "Insurance", icon: healthIcon, clickable: true },
  {
    key: "4",
    label: "My Policies",
    icon: healthIcon,
    clickable: true,
    link: "payswift/my-policies",
  },
  { key: "5", label: "Notifications", icon: mdiBellOutline, clickable: false },
  { key: "6", label: "Settings", icon: mdiCogOutline, clickable: false },
  { key: "7", label: "Support", icon: bxSupport, clickable: false },
];

export const inclusionsSet = [
  "Day care treatments",
  "Pre/Post hospitalisation",
  "Initial waiting period",
  "Covid-19 coverage",
  "Alternate treatments",
  "Organ donor expenses",
  "Emergency road ambulance",
  "Internal Congenital",
  "Lasik Surgery",
];

export const exclusionsSet = [
  "OPD treatment",
  "Home hospitalisation",
  "Psychiatric Illness",
  "Cochlear Impant",
  "Infertility Treatments",
  "Cosmetic/Plastic surgery",
];

export const categoriesHome = [
  { category: "Flights", icon: Rectangle4, clickable: true },
  { category: "Trains", icon: Rectangle41, clickable: false },
  { category: "Cabs", icon: Rectangle42, clickable: false },
  { category: "Places", icon: Rectangle43, clickable: false },
];

export const brandsHome = [
  { alt: "The Clarks Hotel & Resorts", src: dest1 },
  { alt: "IndeHotels & Resorts", src: dest2 },
  { alt: "Karishma’s Hotels & Resorts", src: dest3 },
];

export const listings = [
  {
    name: "POCO C51 (Royal Blue,4GB RAM,64GB Storage)",
    price: "5,499.00",
    brand: "POCO",
    img: "/mob1.png",
  },
  {
    name: "Samsung Galaxy S20 FE 5G (Cloud Navy, 8GB RAM, 128GB Storage)",
    price: "26,800.00",
    brand: "SAMSUNG",
    img: "/mob-1.png",
  },
  {
    name: "Redmi A2 (Aqua Blue, 2GB RAM, 64GB Storage)",
    price: "5,499.00",
    brand: "REDMI",
    img: "/mob-2.png",
  },
  {
    name: "Samsung Galaxy M13 (Midnight Blue, 4GB, 64GB Storage)",
    price: "9,999.00",
    brand: "SAMSUNG",
    img: "/mob-3.png",
  },
];

export const insureBullets = [
  "Protects the device against mechanical and electrical failures",
  "Extension of brand warranty",
  "Coverage starts after expiry of the brand warranty",
  "Hassle-free claim and activation process. Only one repair is covered during the plan term of one year.",
  "This plan cannot be transferred to another person.",
];

export const flightInsureBullets = [
  "24*7 Delayed/lost baggage Assistance",
  "Trip Cancellation up to ₹3,000",
  "Missed Flight up to ₹3,000",
  "Common Career Cancellation of flat ₹3,000",
  "Common Career Delay up to ₹3,000",
];
