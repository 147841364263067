import React from "react";
import { Col, Image, Button } from "antd";
import "./_confirm.scss";
import { useNavigate } from "react-router-dom";

interface OrderConfirmProps {
  confirmTitle: string;
  bookingId?: string;
  children?: React.ReactNode;
}

const OrderConfirm = ({
  confirmTitle,
  bookingId,
  children,
}: OrderConfirmProps): JSX.Element => {
  const navigate = useNavigate();
  const goToHome = () => {
    navigate("/");
  };
  return (
    <Col span={24} className="order-confirm">
      <Image
        preview={false}
        src="/confirmed.svg"
        alt="confirmed"
        className="ok-icon"
      />
      <div className={`heading ${!children && "big"}`}>{confirmTitle}</div>
      {bookingId && <div className="booking-id">Booking Id: {bookingId}</div>}
      <button className="download-button primary">Download E-Ticket</button>
      <button className="download-button primary" onClick={goToHome}>
        Got to Home
      </button>
      {children}
    </Col>
  );
};

export default OrderConfirm;
