import React, { useMemo } from "react";
import { Typography, Card, List } from "antd";
import {
  CreditCardOutlined,
  BankOutlined,
  WalletOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import "./_common.scss";

const { Text } = Typography;

const PaymentMethods = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  const premium = useMemo(() => {
    return localStorage.getItem("premium");
  }, []);
  const amount = useMemo(() => {
    return localStorage.getItem("amount");
  }, []);

  const paymentMethods = [
    {
      title: "Card",
      description: "Visa, MasterCard, RuPay & More",
      icon: <CreditCardOutlined />,
    },
    {
      title: "Netbanking",
      description: "All Indian banks",
      icon: <BankOutlined />,
    },
    {
      title: "Wallet",
      description: "Freecharge and PayZapp",
      icon: <WalletOutlined />,
    },
    {
      title: "UPI",
      description: "Instant payment using UPI App",
      icon: <SwapOutlined />,
    },
  ];

  const handlePaymentMethodClick = () => {
    if (loc.pathname.includes("/payswift")) {
      navigate("/payswift/pay-success", { replace: true });
    } else {
      navigate("/pay-success", { replace: true });
    }
  };

  return (
    <div className="payment-methods-container">
      <div className="payment-header">
        <Text>Total Amount</Text>
        <Text className="inv">#inv_123456789</Text>
        <Text className="to-pay">₹{premium ?? amount ?? "100"}</Text>
      </div>

      <Card title="CARDS, NETBANKING & MORE" className="payment-options">
        <List
          itemLayout="horizontal"
          dataSource={paymentMethods}
          renderItem={(item) => (
            <List.Item
              onClick={handlePaymentMethodClick}
              className="payment-method-item"
            >
              <List.Item.Meta
                avatar={item.icon}
                title={item.title}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
};

export default PaymentMethods;
