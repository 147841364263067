import React from "react";
import { Col, Image, Row } from "antd";
import "./_insurance.scss";
import Button from "../common/button/Button";

interface InsuranceDetailProps {
  heading: string;
  subtitle: string | React.ReactNode;
  description?: string;
  bulletPoints?: string[];
  button?: string;
  onClick?: () => void;
  background?: string;
  buttonLink?: string;
  isSelected?: boolean;
  icon?: string;
  showButton?: boolean;
  poweredBy?: string;
  smallHeading?: string;
}

const EmbedBox = ({
  heading,
  subtitle,
  description,
  bulletPoints,
  button,
  onClick,
  background = "#ffffff",
  buttonLink,
  showButton = true,
  icon,
  isSelected = false,
  poweredBy,
  smallHeading,
}: InsuranceDetailProps) => {
  const [showAll, setShowAll] = React.useState(false);

  return (
    <Col
      span={24}
      className={`insurance-embed-box ${
        background !== "#ffffff" ? "green" : "#fff"
      }`}
      style={{ background: `${background} !important` }}
    >
      <Row justify="start" className="head">
        <Image
          alt="insured_icon"
          className="icon"
          src={icon || "/insure.png"}
          preview={false}
        />
        <Col>
          <div className="heading">
            {heading}
            <span className="small-heading">{smallHeading}</span>
          </div>
          {typeof subtitle === "string" ? (
            <div className="embed-subtitle">{subtitle}</div>
          ) : (
            subtitle
          )}
          {typeof poweredBy === "string" ? (
            <div className={"powered-by"}>{poweredBy}</div>
          ) : (
            poweredBy
          )}
          {showButton && (
            <Button
              className={`bprimary w-100 ${isSelected ? "active" : ""}`}
              onClick={onClick}
              text={button ? button : isSelected ? "Remove" : "Add"}
            />
          )}
        </Col>
      </Row>

      {(description || bulletPoints) && (
        <Row justify="start" className="body">
          <Col>
            <div className="heading">What is Covered</div>
            {description && <div className="description">{description}</div>}
            {bulletPoints && (
              <div className="bullet-points">
                <ul className="list">
                  {!showAll &&
                    bulletPoints.slice(0, 2).map((point, index) => (
                      <li key={index}>
                        {point}
                        {index === 1 && !showAll && (
                          <span
                            className="read-more"
                            onClick={() => setShowAll(true)}
                          >
                            ... Read More
                          </span>
                        )}
                      </li>
                    ))}
                </ul>

                {showAll && (
                  <ul className="list">
                    {bulletPoints.map((point, index) => (
                      <li key={index + 2}>
                        {point}
                        {index === bulletPoints.length - 1 && (
                          <span
                            className="read-more"
                            onClick={() => setShowAll(false)}
                          >
                            ... Read Less
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default EmbedBox;
