import React from "react";
import { Card, Col, Divider, Row } from "antd";

interface TravelCardProps {
  time: {
    departure: string;
    arrival: string;
    duration: string;
  };
  onClick?: () => void;
  price: string;
  name: string;
  via: string;
  img?: string;
  imgProps?: {
    width?: number;
    height?: number;
  };
  startCityCode: string;
  endCityCode: string;
}

const TravelCard = ({
  time,
  price,
  name,
  via,
  img,
  imgProps,
  startCityCode,
  endCityCode,
  onClick,
}: TravelCardProps): JSX.Element => {
  const { departure, arrival, duration } = time;
  return (
    <Card className="travel-card" onClick={onClick}>
      <Row>
        <Col span={24}>
          <div className="travel-head">
            <Row className="top">
              <Col span={4} className="time left">
                <div className="time">{departure}</div>
                <div className="via">{startCityCode}</div>
              </Col>
              <Col span={16}>
                <Divider className="middle">
                  <img src={img} alt={name} style={imgProps} />
                </Divider>
              </Col>
              <Col span={4} className="time right">
                <div className="time">{arrival}</div>
                <div className="via">{endCityCode}</div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <div className="middle">
            <div className="duration">{duration}</div>
            {via && <div className="via">{via}</div>}
          </div>
        </Col>
      </Row>
      <Divider dashed />
      <Row justify="space-between">
        <div className="bottom">
          <div className="name">{name}</div>
          <div className="price">₹{price}</div>
        </div>
      </Row>
    </Card>
  );
};

export default TravelCard;
