import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Landing from "../pages/Landing";
import Components from "../pages/Components";
import PaySuccess from "../pages/nomad/PaySuccess";
import Home from "../pages/nomad/Home";
import Listing from "../pages/nomad/Listing";
import Checkout from "../pages/nomad/Checkout";
import Trips from "../pages/nomad/Trips";
import BookFlightForm from "../pages/nomad/BookFlightForm";
import PaymentMethods from "../pages/Payment";
import PlanSelection from "../pages/payswift/SelectPlan";
import FillDetails from "../pages/payswift/FillDetails";
import InsurancePlus from "../pages/payswift/InsurancePlus";
import PayCart from "../pages/payswift/Cart";
import PaySuccessPolicy from "../pages/payswift/PaySuccess";
import MyPolicies from "../pages/payswift/MyPolicies";

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/welcome" element={<Landing />} />
      <Route path="/" element={<Home />} />

      <Route path="*" element={<Home />} />
      <Route path="insurance" element={<InsurancePlus />} />
      <Route path="listing" element={<Listing />} />
      <Route path="checkout" element={<Checkout />} />
      <Route path="book-flights" element={<BookFlightForm />} />
      <Route path="to-payment" element={<Navigate to="/payment" replace />} />
      <Route path="payment" element={<PaymentMethods />} />
      <Route path="pay-success" element={<PaySuccess />} />
      <Route path="trips" element={<Trips />} />

      <Route path="payswift">
        <Route path="select-plan" element={<PlanSelection />} />
        <Route path="insurance" element={<InsurancePlus />} />
        <Route path="fill-details" element={<FillDetails />} />
        <Route path="cart" element={<PayCart />} />
        <Route path="payment" element={<PaymentMethods />} />
        <Route path="pay-success" element={<PaySuccessPolicy />} />
        <Route path="my-policies" element={<MyPolicies />} />
      </Route>

      {/* Components */}
      <Route path="/components/usage" element={<Components />} />
    </Routes>
  );
};

export default Router;
