import React from "react";
import { useOrders } from "../../context/PlanProvider";
import EmbedBox from "../../components/insurance/EmbedBox";
import { flightInsureBullets } from "../../const/nomad";
import DetailBox from "../../components/flight/DetailBox";
import NoItem from "../../components/common/NoItem";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const { hasOrders } = useOrders();
  const navigate = useNavigate();
  return (
    <>
      {hasOrders && (
        <div className="orders-page">
          <DetailBox />
          <EmbedBox
            heading="Your Trip is Insured"
            subtitle="Trip Secure by Bharatsure"
            poweredBy="Insurance Powered by ICICI Lombard"
            background="#F6FFF6"
            bulletPoints={flightInsureBullets}
            showButton={false}
          />
        </div>
      )}
      {!hasOrders && (
        <NoItem
          label="No trips yet"
          btnCTA={
            <button
              className="primary text-white"
              onClick={() => {
                navigate("/book-flights");
              }}
            >
              Get Started
            </button>
          }
        />
      )}
    </>
  );
};

export default Orders;
