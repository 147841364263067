import React, { useMemo, useState } from "react";
import { Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import "./_styles.scss";
import { useNavigate } from "react-router-dom";
import { useOrders } from "../../context/PlanProvider";
import FlightDetails from "../../assets/nomad/flight-detail.svg";
import EmbedBox from "../../components/insurance/EmbedBox";
import { flightInsureBullets } from "../../const/nomad";

const { Text } = Typography;

const Cart = () => {
  const navigate = useNavigate();
  const [insuranceAdded, setInsuranceAdded] = useState(true);
  const { updateHasOrders } = useOrders();
  const premium = useMemo(() => {
    return insuranceAdded ? "9,699" : "9,450";
  }, [insuranceAdded]);

  const onSubmit = () => {
    updateHasOrders(true);
    localStorage?.setItem("amount", premium);
    localStorage?.removeItem("premium")
    navigate("/to-payment", { replace: true });
  };

  const toggleInsurance = () => {
    setInsuranceAdded(!insuranceAdded);
  };

  return (
    <div className="checkout">
      <div className="flight-info">
        <div className="flight-details">
          <img
            src={FlightDetails}
            alt="Flight Details"
            className="flight-details-img"
          />
          {/* <img
            src="flight-icon.png"
            alt="Flight Icon"
            className="flight-icon"
          /> */}
          {/* <Text className="route">DEL-BOM</Text>
          <Text className="schedule">
            Fri, Mar | 22:55-01:10 | 2h 15m | Non-Stop
          </Text> */}
        </div>
      </div>
      <div className="passenger-info">
        <div className="section-header">
          <Text className="section-title">Passenger</Text>
          <EditOutlined className="edit-icon" />
        </div>
        <div className="passenger">
          <Text className="passenger-name">Anupam Bagchi</Text>
          <Text className="passenger-email">anupambagchi21@gmail.com</Text>
          <Text className="passenger-phone">+91 9418408685 • Adult</Text>
        </div>
      </div>

      <EmbedBox
        heading="Protect Your Trip for just ₹249"
        subtitle="Trip Secured by Bharatsure"
        bulletPoints={flightInsureBullets}
        onClick={toggleInsurance}
        isSelected={insuranceAdded}
        poweredBy="Insurance Powered by ICICI Lombard"
        smallHeading="/traveller"
      />

      <div className="total-section">
        <div className="total">
          <Text className="total-title">Total</Text>
          <Text className="total-price">₹{premium}</Text>
        </div>

        <button onClick={onSubmit} className="proceed-button">
          Proceed
        </button>
      </div>
    </div>
  );
};

export default Cart;
